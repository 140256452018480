@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

:root {
  --body-background: #1f2122;
  --body-color: #f4e9cd;
  --body-accent-background: linear-gradient(to top, #68ccc4 0%, #003329 100%);
  --body-accent: #359381;
  --body-text: #edf2f4;
  --body-filter: brightness(100%);

  --heading: clamp(40px, 4vw, 1920px);

  --ff-primary: "Times New Roman", sans-serif;
  --ff-secondary: "Work Sans", sans-serif;
  --ff-accent: "Sofia", sans-serif;

  --step--2: clamp(1.04rem, calc(0.97rem + 0.38vw), 1.44rem);
  --step--1: clamp(1.25rem, calc(1.15rem + 0.52vw), 1.8rem);
  --step--1-a: clamp(1.27rem, calc(1.17rem + 0.54vw), 1.9rem);
  --step-0: clamp(1.5rem, calc(1.36rem + 0.71vw), 2.25rem);
  --step-0-1: clamp(1.7rem, calc(1.38rem + 0.73vw), 2.27rem);
  --step-1: clamp(1.8rem, calc(1.61rem + 0.96vw), 2.81rem);
  --step-2: clamp(2.16rem, calc(1.9rem + 1.29vw), 3.52rem);
  --step-3: clamp(2.59rem, calc(2.25rem + 1.72vw), 4.39rem);
  --step-4: clamp(3.11rem, calc(2.66rem + 2.27vw), 5.49rem);
  --step-5: clamp(3.73rem, calc(3.14rem + 2.98vw), 6.87rem);
}

[data-theme="dark"] {
  --body-background: #1f2122;
  --body-color: #edf2f4;
  --body-accent-background: linear-gradient(to top, #003329 0%, #68ccc4 100%);
  --body-filter: brightness(100%);
}

* {
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  width: 0.000000001em;
  background-color: #000000;
}

body::-webkit-scrollbar-thumb {
  background-color: #000000;
}

body {
  font-size: 16px;
  margin: 0;
  padding: 0;

  background-color: var(--body-background);

  color: var(--body-color);
}

.workapp {
  width: 100%;
  height: 100%;
  overflow: auto;
}

h1 {
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: italic;

  text-shadow: 1px 1px #000, 2px 2px #000, 3px 3px #000;
}

h2 {
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: italic;

  text-shadow: 1px 1px #000, 2px 2px #000, 3px 3px #000;
}

h3 {
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: italic;

  text-shadow: 1px 1px #000, 2px 2px #000, 3px 3px #000;
}

p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

li {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

button {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  text-shadow: 1px 1px #000, 2px 2px #000, 3px 3px #000;
}

.logo-name {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.contact-logo-container {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.contact-container input,
.contact-container textarea {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  h1 {
    font-size: 2.5rem; /* 40px on tablet */
  }

  h2 {
    font-size: 2rem; /* 32px on tablet */
  }

  h3 {
    font-size: 1.75rem; /* 28px on tablet */
  }

  p {
    font-size: 1.125rem; /* 18px on tablet */
  }

  li {
    font-size: 1.125rem; /* 18px on tablet */
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  h1 {
    font-size: 3rem; /* 48px on desktop */
  }

  h2 {
    font-size: 2.5rem; /* 40px on desktop */
  }

  h3 {
    font-size: 2rem; /* 32px on desktop */
  }

  p {
    font-size: 1.25rem; /* 20px on desktop */
  }

  li {
    font-size: 1.25rem; /* 20px on desktop */
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  h1 {
    font-size: 3.5rem; /* 56px on large desktop */
  }

  h2 {
    font-size: 2.75rem; /* 44px on large desktop */
  }

  h3 {
    font-size: 2.25rem; /* 36px on large desktop */
  }

  p {
    font-size: 1.375rem; /* 22px on large desktop */
  }

  li {
    font-size: 1.375rem; /* 22px on large desktop */
  }
}

/* Extra extra large devices (1920px and up) */
@media (min-width: 1920px) {
  h1 {
    font-size: 4rem; /* 64px on extra large desktop */
  }

  h2 {
    font-size: 3rem; /* 48px on extra large desktop */
  }

  h3 {
    font-size: 2.5rem; /* 40px on extra large desktop */
  }

  p {
    font-size: 1.5rem; /* 24px on extra large desktop */
  }

  li {
    font-size: 1.5rem; /* 24px on extra large desktop */
  }
}
