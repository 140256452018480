.pano-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  margin-top: 5vh;
}

.panorendererContainer {
  width: 100%;
  height: 100%;
}

.pano-wrapper {
  position: relative;
  border-radius: 10px;
  border: 3px solid var(--body-accent);
  overflow: hidden;
  width: 90vw;
  height: 80vh;
}

.pano-text {
  position: absolute; /* Add this line */
  left: 0; /* Add this line */
  bottom: 0; /* Add this line */
  margin: 10px; /* Add some space from the edge */
  color: var(--body-color);
}

.scroll-arrow {
  position: absolute;
  bottom: 20px; /* Adjust this value as necessary */
  left: 50%;
  transform: translateX(-50%);
  width: 50px; /* Adjust this value as necessary */
  height: 50px; /* Adjust this value as necessary */
  background: url("downarrow1.svg") no-repeat center center;
  background-size: contain;
  z-index: 10;
}
