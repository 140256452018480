.navbar-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}
.navlogo {
  width: 25%;
}
.navlogo:hover {
  cursor: pointer;
}

.navigation {
  display: flex;
  align-items: center;
}

.navigation button {
  text-decoration: none;
  color: var(--body-color);
  padding: 6px 15px;
  border-radius: 20px;
  margin: 0 10px;

  border: none; /* Removes default button border */
  background: none; /* Removes default button background */
  cursor: pointer; /* Changes the cursor to a hand when hovering over the button */
}

.navigation button:hover {
  background: var(--body-accent);
  color: var(--body-color);
}

.navigation a {
  text-decoration: none;
  color: var(--body-color);
  padding: 6px 15px;
  border-radius: 20px;
  margin: 0 10px;
}

.navigation a:hover {
  background: var(--body-accent);
  color: var(--body-color);
}

.hidden {
  display: none;
}

/* iPads in portrait */
@media (min-width: 768px) {
  .navlogo {
    width: 20%;
  }
}

/* Small laptops */
@media (min-width: 1024px) {
  .navlogo {
    width: 13%;
  }
}

/* Laptops */
@media (min-width: 1280px) {
  .navlogo {
    width: 10%;
  }
}

/* Desktops */
@media (min-width: 1600px) {
  .navlogo {
    width: 10%;
  }
}

/* Desktops */
@media (min-width: 1792px) {
  .navlogo {
    width: 8%;
  }
}

/* Larger Desktops */
@media (min-width: 1920px) {
  .navlogo {
    width: 8%;
  }
}
